import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../components/button/button.component';

import { AsyncPipe } from '@angular/common';
import { IonInput, ModalController } from '@ionic/angular/standalone';
import { FormsModule } from '@angular/forms';
import { InputErrorComponent } from '../../components/input-error/input-error.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-parental-guard-modal',
  templateUrl: './parental-guard-modal.page.html',
  styleUrls: ['./parental-guard-modal.page.scss'],
  standalone: true,
  imports: [TranslateModule, ButtonComponent, AsyncPipe, IonInput, FormsModule, InputErrorComponent],
})
export class ParentalGuardModalPage implements OnInit {
  number1?: number;
  number2?: number;
  userAnswer?: number;
  incorrectAnswer = false;
  emptyAnswer = false;

  private correctAnswer?: number;

  constructor(private modalController: ModalController) {}

  ngOnInit(): void {
    this.generateRandomNumbers();
  }

  generateRandomNumbers(): void {
    this.number1 = Math.floor(Math.random() * 7) + 3; // between 3 and 10
    this.number2 = Math.floor(Math.random() * 7) + 3; // between 3 and 10
    this.correctAnswer = this.number1 * this.number2;
  }

  checkResult(): void {
    if (this.userAnswer) {
      this.emptyAnswer = false;
      if (this.userAnswer === this.correctAnswer) {
        void this.modalController.dismiss({ allowNext: true });
        this.incorrectAnswer = false;
      } else {
        this.userAnswer = undefined;
        this.incorrectAnswer = true;
        this.generateRandomNumbers();
      }
    } else {
      this.emptyAnswer = true;
      this.incorrectAnswer = false;
    }
  }

  closeModal(params: { allowNext: boolean }): void {
    void this.modalController.dismiss({ allowNext: params.allowNext });
  }
}
