import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { ParentalGuardModalPage } from '../modals/parental-guard/parental-guard-modal.page';
import { AppService, PlatformType } from './app.service';

@Injectable({ providedIn: 'root' })
export class ParentalGuardService {
  constructor(
    private modalController: ModalController,
    private appService: AppService,
  ) {}

  async parentalGuardCompleted(): Promise<boolean> {
    if (this.appService.platformType !== PlatformType.IOS) {
      return Promise.resolve(true);
    }

    const parentalGuardModal = await this.modalController.create({
      component: ParentalGuardModalPage,
      cssClass: 'wr-modal not-fullscreen',
    });

    void parentalGuardModal.present();
    const response = await parentalGuardModal.onDidDismiss();

    return !!response.data?.allowNext;
  }
}
