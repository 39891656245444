<div class="modal-container">
  <div class="close-modal" (click)="closeModal({ allowNext: false })" data-cy="parental-guard-close-modal-btn">
    <img src="assets/icon/blue-close.svg" alt="" />
  </div>

  <div class="title">{{ 'PWA_parentalGuard_popup_header' | translate }}</div>

  <p class="operation">{{ number1 }} x {{ number2 }} =</p>

  <ion-input type="number" [(ngModel)]="userAnswer" class="wr-input white" [class.error]="incorrectAnswer || emptyAnswer"></ion-input>

  @if (emptyAnswer) {
    <div class="error">{{ 'PWA_parentalGuard_field_error_blank' | translate }}</div>
  }

  @if (incorrectAnswer) {
    <div class="error">{{ 'PWA_parentalGuard_field_error_incorrect' | translate }}</div>
  }

  <app-button [color]="'violet'" [size]="'normal'" [label]="'PWA_parentalGuard_CTA_confirm' | translate" (clicked)="checkResult()" />

  <div class="cancel" (click)="closeModal({ allowNext: false })" data-cy="parental-guard-cancel-btn">
    {{ 'PWA_parentalGuard_CTA_link_cancel' | translate }}
  </div>
</div>
